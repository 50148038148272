import React from 'react';
import Header from '../components/UI/Header';
import PreesmAppBar from '../components/UI/PreesmAppBar';

import bgImg from '../assets/header/intermediaries_header_bg.svg';
import cameraMan from '../assets/header/cameraMan.png';
import HeaderTitle from '../components/UI/HeaderTitle';
import { Box } from '@mui/system';
import { Container } from '@mui/material';
import YouKnowFootbal from '../components/YouKnowFootball/YouKnowFootbal';
import Subscribe from '../components/UI/Subscribe/Subscribe';
import OurCasesSwipe from '../components/OurCases/OurCasesSwipe';
import OurMissionInNumbers from '../components/OurMissionInNumbers/OurMissionInNumbers';
import OurPartners from '../components/OurPartners/OurPartners';
import OurNews from '../components/OurNews/OurNews';
import FAQ from '../components/FAQ/FAQ';
import { Colors } from '../types/colors';
import Footer from '../components/Footer/Footer';

export const Intermediaries: React.FC = () => {
  return (
    <>
      <Header bgImg={bgImg}>
        <PreesmAppBar />
        <HeaderTitle content="our offer for intermedi&shy;aries" />
        <Container>
          <Box
            sx={{
              position: 'absolute',
              bottom: '0%',
              top: '0%',
              alignItems: 'flex-end',
              display: 'flex',
              maxWidth: '669px',
            }}
          >
            <img src={cameraMan} alt="camera man" width="100%" />
          </Box>
        </Container>
      </Header>
      <main>
        <YouKnowFootbal />
        <Subscribe color={Colors.green} />
        <OurCasesSwipe />
        <OurMissionInNumbers />
        <OurPartners />
        <OurNews />
        <FAQ />
        <Subscribe color={Colors.green} />
      </main>
      <Footer />
    </>
  )
}
