import React from 'react';
import SectionTitle from '../UI/SectionTitle';
import { Box, Container, Grid, Typography } from '@mui/material';

import preesm from '../../assets/whoWeAre/preesm.png';

interface Props {
  
}

const WhoWeAre = (props: Props) => {
  return (
    <section>
      <SectionTitle text="who we are" />
      <Box
        sx={{
          bgcolor: 'primary.main',
          px: {xs: '0px', md: '30px'},
          py: {xs: '40px', md: '80px'}
        }}
      >
        <Container sx={{mb: {xs: '20px', md: '60px'}}}>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={10}>
              <Grid container spacing={{xs: '20px', md: '32px'}}>
                <Grid item sm={6}>
                  <Typography variant="body1" color="text.secondary">
                    Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="body1" color="text.secondary">
                    Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <img src={preesm} alt="preesm tag line" width="100%" />
        <Container sx={{mt: {xs: '20px', md: '60px'}}}>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={10}>
              <Grid container spacing={{xs: '20px', md: '32px'}}>
                <Grid item sm={6}>
                  <Typography variant="body1" color="text.secondary">
                    Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="body1" color="text.secondary">
                    Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </section>
  )
}

export default WhoWeAre;
