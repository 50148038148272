import React from 'react';
import { Box } from '@mui/material';

import contactUsBg from '../../assets/contactUs/contactUsBg.jpg';

import './ContactUs.scss';

import ContactUsForm from '../UI/ContactUsForm/ContactUsForm';

interface Props {
  
}

const ContactUs = (props: Props) => {
  return (
    <Box
      component="section"
      sx={{
        background: `url('${contactUsBg}') center/cover no-repeat`,
        py: '80px',
      }}
    >
      <ContactUsForm />
    </Box>
  )
}

export default ContactUs;
