import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import {ReactComponent as ExpandMoreIcon} from '../../assets/FAQ/expand_more.svg';
import { AccordionItemType } from '../../types/accordionItem';
import { Box } from '@mui/material';

interface Props {
  content: AccordionItemType;
  state: string | false;
  switcher: (state: string | false) => void;
}

const AccordionItem = (props: Props) => {
  const { content, state, switcher } = props;

  const handleChange =
  (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    switcher(newExpanded ? panel : false);
  };

  return (
    <Accordion
      sx={{
        bgcolor: 'transparent',
        boxShadow: 'none',
        backgroundImage: 'none',
        '&.Mui-expanded': {mb: 0},
      }}
      expanded={state === content.order}
      onChange={handleChange(content.order)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          flexDirection: 'row-reverse',
          pl: {
            xs: 2,
            lg: 8,
          },
          minHeight: '0px',
          '.MuiAccordionSummary-content': {
            my: {xs: '20px', md: '40px'},
          }
        }}
      >
        <Typography
          sx={{
            pl: 2,
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            fontWeight: 700,
            fontSize: '1.5rem', // 24px (base: 16px)
            lineHeight: '1.75rem',
            letterSpacing: '0.05em',
            textTransform: 'uppercase',
            color: `${state === content.order ? 'primary.main' : 'text.primary'}`
          }}
        >
          {content.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          pl: {xs: '53px', sm: '0px'},
        }}
      >
        <Box
          sx={{
            pt: {xs: '20px', sm: '25px', md: '30px', lg: '40px'},
            pb: {xs: '40px', sm: '50px', md: '60px', lg: '80px'},
            maxWidth: {xs: '100%', sm: '70%'},
            margin: '0 auto',
          }}
        >
          <Typography>
            {content.content}
          </Typography>
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

export default AccordionItem;
