import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import player from '../../assets/ourMissionInNumbers/football_player.svg';
import gate from '../../assets/ourMissionInNumbers/football_gate.svg';
import tv from '../../assets/ourMissionInNumbers/football_game_on_tv.svg';
import players from '../../assets/ourMissionInNumbers/football_players_on_game.svg';
import OurMissionInNumbersItem from './OurMissionInNumbersItem';
import SectionTitle from '../UI/SectionTitle';

interface Props {

}

const OurMissionInNumbers = (props: Props) => {
  const customTitle = (
    <Typography component="span"
      align="center"
      sx={{
        fontFamily: "'Hoxton800italic', 'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontWeight: 800,
        fontStyle: 'italic',
        letterSpacing: '0.05em',
        '&.MuiTypography-root': {
          fontSize: '4rem', // 64px (base: 16px)
          lineHeight: '4.125rem',
        }
      }}
    >
      <span
        style={{
          fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
          fontWeight: 700,
          fontStyle: 'italic',
          letterSpacing: '0.05em',
          fontSize: '4rem', // 64px (base: 16px)
        }}
      >
        €
      </span>
        100
      <span
        style={{
          fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
          fontWeight: 700,
          fontStyle: 'italic',
          letterSpacing: '0.05em',
          fontSize: '4rem', // 64px (base: 16px)
        }}
      >
        m
      </span>
    </Typography>
  )

  return (
    <section>
      <Container>
        <SectionTitle text="our mission in numbers" />
        <Grid container justifyContent="center" gap="30px">
          <OurMissionInNumbersItem picPath={player} alt="football player" title="800" subTitle="New talents found" />
          <OurMissionInNumbersItem picPath={gate} alt="football gate" title="3+" subTitle="years in business" />
          <OurMissionInNumbersItem picPath={tv} alt="football game on tv" title={customTitle} subTitle="earned" />
          <OurMissionInNumbersItem picPath={players} alt="football players" title="5+" subTitle="players gone pro" />
        </Grid>
      </Container>
    </section>
  )
}

export default OurMissionInNumbers;
