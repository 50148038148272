import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import styles from './Subscribe.module.scss';
import { SubscribeForm } from '../SubscribeForm';

interface Props {
  children?: React.ReactNode;
  color: string;
}


const Subscribe: React.FC<Props> = (props: Props) => {
  const { color } = props;

  const hexToRgb = (hex: string) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    if (result) {
        var r= parseInt(result[1], 16);
        var g= parseInt(result[2], 16);
        var b= parseInt(result[3], 16);
        return r+","+g+","+b;
    }

    return '0,0,0';
  }

  return (
    <section className={styles.subscribe}>
      <div style={{backgroundColor: `rgba(${hexToRgb(color)},0.65)`}}>
        <Container>
          <Typography variant="h6" sx={{pt: {xs: 4, sm: 10}, pb: 4}}>
            subscribe and get earlybird tokens
          </Typography>
          <Grid container item md={8} sx={{pb: {xs: '36px', md: '80px'}}}>
            <SubscribeForm />
          </Grid>
        </Container>
      </div>
    </section>
  )
}

export default Subscribe;
