import React from 'react';
import { Container, Typography, Grid, Divider, useTheme, useMediaQuery } from '@mui/material';
import whatWeDo from '../../assets/whatWeDo/what_we_do.svg';
import SectionTitle from '../UI/SectionTitle';
import WhatWeDoGraph from './WhatWeDoGraph';

interface Props {
  
}

const WhatWeDo = (props: Props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  interface Deed {
    title: string,
    body: string,
  }

  const deeds: Deed[] = [
    {
      title: 'player inserction',
      body: 'An intermediary creates the player’s profile, enters data on a professional datasheet, and provides videos showing the skills of the player.'
    },
    {
      title: 'Evaluation',
      body: 'The player is evaluated by the network. His level of skill is represented by a number on a scale.'
    },
    {
      title: 'Tokenisation',
      body: 'For the top players, we create fractionnal NFTs representing them and their career. This allows to tokenize the value of players.'
    },
    {
      title: 'Investment',
      body: 'Investors can buy fractions of the NFTs of players. The more they buy, the more they own.'
    },
    {
      title: 'funding',
      body: 'Both from the money poured into his NFT, and from our support, a player can win career funding.'
    },
    {
      title: 'turning pro',
      body: 'Professional clubs are put in contact with the player, and a deal is signed. Congratulations!'
    },
  ];



  return (
    <section>
      <Container>
        <SectionTitle text="what we do" />
        <Grid container justifyContent="center" spacing={2}>

          <Grid item xs={12} md={5}>
            <Divider sx={{
              '&.MuiDivider-root::before, &.MuiDivider-root::after': {
                borderTop: '2px solid #fff',
              },
              '.MuiDivider-wrapper': {px: 3}
            }}>
              <img src={whatWeDo} alt="what we do" />
            </Divider>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" align="center" sx={{mb: 7}}>
              We connect blockchain and NFTs to the world of football.
            </Typography>
          </Grid>
        </Grid>
        {matches ? (
          <WhatWeDoGraph />
        ) : (
          <>
            {deeds.map((deed: Deed, i: number) => (
              <Grid container spacing={1} sx={{mb: 7}} key={deed.title}>
                <Grid item xs={1} sx={{mr: 1}}>
                  <Typography variant="h2" sx={{
                    color: 'primary.main',
                  }}>
                    {i + 1}
                  </Typography>
                </Grid>
                <Grid item xs={10} container direction="column">
                  <Grid item>
                    <Typography variant="h5" sx={{mb: 1}}>
                      {deed.title}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">
                      {deed.body}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </>
        )}
      </Container>
    </section>
  )
}

export default WhatWeDo;
