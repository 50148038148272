import React from 'react';
import { Container, Typography, Grid } from '@mui/material';
import ourMissionImg from '../../assets/ourMission/ourMission.jpg'
import SectionTitle from '../UI/SectionTitle';

interface Props {
  
}

const OurMission = (props: Props) => {
  return (
    <section>
      <Container>
        <SectionTitle text="our mission" />
        <Grid container spacing={6}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            item
            md={5}
          >
            <Typography variant="body2" gutterBottom>
              Our goal is to help young talent around the world to get to the biggest arenas.
            </Typography>
            <Typography variant="body2" gutterBottom>
              Through systematisation and automation, we facilitate the evaluation of young prospects around the world, and allows them to avoid transfer hell.
            </Typography>
            <Typography variant="body2" gutterBottom>
              If a player is successfull and signs a contract throught our platform, we give money to every actor that took part in the young player’s career.
            </Typography>
            <Typography variant="body2">
              We help the world’s best talent attract attention, while providing the clubs with rough diamonds.
            </Typography>
          </Grid>
          <Grid item xs={12} md={7} alignItems="center" container>
            <img src={ourMissionImg} width="100%" alt="our mission video" />
          </Grid>
        </Grid>
      </Container>
    </section>
  )
}

export default OurMission;
