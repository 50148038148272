import React, { ReactNode } from 'react';
import PreesmAppBar from '../components/UI/PreesmAppBar';
import { Box, Container, Grid, Typography, IconButton } from '@mui/material';
import backwardArrow from '../assets/breathtakingArticle/backwardArrow.svg';
import sectionPic from '../assets/breathtakingArticle/sectionPic.jpg';
import drogba from '../assets/ourCases/drogba_page/drogba.jpg';
import Footer from '../components/Footer/Footer';
import OurCasesSwipe from '../components/OurCases/OurCasesSwipe';

interface Props {
  
}

const DidierDrogba = (props: Props) => {
  const styles = {
    paragraphTitle: {
      '&.MuiTypography-root': {
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontWeight: 700,
        fontSize: '2.25rem', // 36px (base: 16px)
        lineHeight: '2.625rem',
        letterSpacing: '0.05em',
        wordWrap: 'break-word',
        hyphens: 'manual',
      },
      mb: '20px'
    }
  };

  const articleSection: ReactNode = (
    <Grid container justifyContent="flex-end" component="section">
      <Grid item xs={12} md={8}>
        <Typography
          component="h2"
          sx={{
            ...styles.paragraphTitle,
          }}
        >
          Sub title
        </Typography>
      </Grid>
      <Grid item xs={12} md={2}></Grid>
      <Grid item xs={12} md={8}>
        <Typography
          variant="body1"
          sx={{mb: '20px'}}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </Typography>
      </Grid>
      <Grid item xs={12} md={2}></Grid>
      <Grid item xs={12} md={8}>
        <Typography
          variant="body1"
          sx={{mb: '20px'}}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </Typography>
      </Grid>
      <Grid item xs={12} md={2}></Grid>
      <Grid
        item
        xs={12}
        md={9}
        sx={{
          my: {
            xs: '50px', md: '80px'
          }
        }}
      >
        <img src={sectionPic} alt="young coach" width="100%"/>
      </Grid>
      <Grid item xs={12} md={1}></Grid>
    </Grid>
  );

  return (
    <>
      <header>
        <PreesmAppBar />
      </header>
      <main>
        <article>
          <Container
            sx={{
              pt: { xs: '80px', sm: '80px', md: '160px'},
              mb: '102px'
            }}
          >
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} md={10}>
                <Grid item xs={12}>
                  <IconButton
                    aria-label=""
                    sx={{
                      ml: -1,
                      mb: '20px'
                    }}
                  >
                    <img src={backwardArrow} alt="" />
                  </IconButton>
                </Grid>
                <Grid container columns={10} spacing="52px">
                  <Grid item lg={4}>
                    <Box
                      sx={{
                        maxWidth: '392px',
                      }}
                    >
                      <img src={drogba} alt="drogba" width="100%" />
                    </Box>
                  </Grid>
                  <Grid item lg={6}>
                    <Typography
                      variant="h1"
                      sx={{
                        lineHeight: '100%',
                        letterSpacing: '0.05em',
                        wordWrap: 'break-word',
                        hyphens: 'manual',
                        mb: '40px'
                      }}
                    >
                      Didier<br></br>Drogba
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      component="span"
                      sx={{
                        lineHeight: '100%',
                      }}
                    >
                      Striker
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
          <Container>
            {articleSection}
            {articleSection}
          </Container>
        </article>
        <OurCasesSwipe />
      </main>
      <Footer />
    </>
  )
}

export default DidierDrogba;
