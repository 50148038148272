import React from 'react';
import { AppBar, Box, Container, Toolbar } from '@mui/material';
import HeaderMenu from '../Header/HeaderMenu';
import HeaderMenuMobile from '../Header/HeaderMenuMobile';
import Logo from './Logo';

interface Props {
  
}

const PreesmAppBar = (props: Props) => {
  return (
    <AppBar
      position="static"
      sx={{
        position: 'absolute',
        py: 2,
        background: 'linear-gradient(180deg, rgba(15,21,33,1) 66%, rgba(0,0,0,0) 99%, rgba(0,0,0,0) 100%)',
        zIndex: 99,
      }}
      component={'div'}
    >
      <Container>
        <Toolbar disableGutters={true}>
          <Logo />
          <HeaderMenuMobile />
          <Box sx={{ ml: 'auto', display: { xs: 'none', sm: 'none', md: 'block'} }}>
            <HeaderMenu />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default PreesmAppBar;
