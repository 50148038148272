import React from 'react';
import { Button } from '@mui/material';
import logo from '../../assets/logo_white.svg';
import { Link } from 'react-router-dom';

interface Props {
  
}

const Logo = (props: Props) => {
  return (
    <Button
      disableRipple
      component={Link}
      to="/"
      sx={{
        p: 0,
        maxWidth: '147px',
        width: '100%',
        '&:hover': {bgcolor: 'transparent'},
      }}
    >
      <img src={logo} alt="logo" width="147px" />
    </Button>
  )
}

export default Logo;
