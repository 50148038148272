import React from 'react';
import Header from '../components/UI/Header';
import PreesmAppBar from '../components/UI/PreesmAppBar';
import bgImg from '../assets/header/home_header_bg.jpg';
import OurMission from '../components/OurMission/OurMission';
import WhatWeDo from '../components/WhatWeDo/WhatWeDo';
import Subscribe from '../components/UI/Subscribe/Subscribe';
import { Colors } from '../types/colors';
import OurMissionInNumbers from '../components/OurMissionInNumbers/OurMissionInNumbers';
import OurPartners from '../components/OurPartners/OurPartners';
import OurNews from '../components/OurNews/OurNews';
import FAQ from '../components/FAQ/FAQ';
import Footer from '../components/Footer/Footer';
import OurCasesSwipe from '../components/OurCases/OurCasesSwipe';
import HeaderWithForm from '../components/UI/HeaderWithForm';

export const Home: React.FC = () => {

  return (
    <>
      <Header bgImg={bgImg}>
        <PreesmAppBar />
        <HeaderWithForm
          title="Bring talent to light"
          subtitle="The start of success"
        />
      </Header>
      <main>
        <OurMission />
        <WhatWeDo />
        <OurCasesSwipe />
        <Subscribe color={Colors.blue} />
        <OurMissionInNumbers />
        <OurPartners />
        <OurNews />
        <FAQ />
      </main>
      <Footer />
    </>
  )
}

