import { Typography } from '@mui/material';
import React from 'react';

interface Props {
  number: number;
  fontSizePx: string;
}

const YellowNumber = (props: Props) => {
  const {number, fontSizePx } = props;

  return (
    <Typography
      sx={{
        '&.MuiTypography-root': {
          fontFamily: "'Hoxton800italic', 'Roboto', 'Helvetica', 'Arial', sans-serif",
          fontWeight: 800,
          fontStyle: 'italic',
          fontSize: fontSizePx,
          lineHeight: fontSizePx,
          letterSpacing: '0.05em',
        },
        color: 'primary.main',
      }}
    >
      {number}
    </Typography>
  )
}

export default YellowNumber;
