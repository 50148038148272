import React from 'react';
import Container from '@mui/material/Container';
import SectionTitle from '../UI/SectionTitle';
import { Grid, Typography } from '@mui/material';

import crowd from '../../assets/youKnowFootball/crowd.jpg';
import youngPlayers from '../../assets/youKnowFootball/young_players.jpg';


interface Props {
  
}

const YouKnowFootbal = (props: Props) => {
  const paragraphStyle = {
    mb: '20px',
  };

  const picStyle = {
    marginBottom: `${paragraphStyle.mb}`,
  }

  return (
    <section>
      <Container sx={{mb: {xs: '70px', md: '120px'}}}>
        <SectionTitle text="you know football" />
        <Grid container justifyContent="center">
          <Grid item xs={12} md={10}>
            <Grid container columnSpacing="36px">
              <Grid item xs={12} sm={6} container direction="column">
                <Typography variant="body1" sx={{...paragraphStyle}}>
                  You spend your time around football fields, know the game well, and want to earn money?
                </Typography>
                <Typography variant="body1" sx={{...paragraphStyle}}>
                  Become an intermediary! You can earn rewards based on your performance and the players you signed up, reviewed, and mentored.
                </Typography>
                <Typography variant="body1" sx={{...paragraphStyle}}>
                  Whether you a are a parent, a agent, an avid supporter, your knowledge helps.
                </Typography>
                <img src={crowd} alt="crowd at a stadium" width="100%" style={picStyle} />
                <Typography variant="body1" sx={{...paragraphStyle}}>
                  Evaluate player, watch their videos, and go see them play. Leverage your knowledge of the game to grade young players.
                </Typography>
                <Typography variant="body1" sx={{...paragraphStyle}}>
                  Decide on the players good enough to get their own NFT. You make their career. But be fair, your rank will go down if you lie to the system.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} container direction="column">
                <img src={youngPlayers} alt="crowd at a stadium" width="100%" style={picStyle} />
                <Typography variant="body1" sx={{...paragraphStyle}}>
                  Work your way up in a hierarchical system: you start as wannabee. After a few successfull sign-ups and evaluations, your opinion and your experience are recognised and you attain the scout rank. If your work is exceptionnal, you can reach the rank of expert and give your opinion on the cream of the top. 
                </Typography>
                <Typography variant="body1" sx={{...paragraphStyle}}>
                  If your evaluations are good, and the players that you introduced to the platform, or the ones that you evaluated make it to the end, you will get rewarded for your contributions. You can finally make a living doing what you love.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

export default YouKnowFootbal;
