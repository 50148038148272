import React from 'react';
import { Fade, useTheme } from '@mui/material';
import { NavLink } from 'react-router-dom';

import caret_right from '../../assets/header/caret_right.svg';
import styles from './HeaderMenuItemPopper.module.scss';

interface Props {
  onMouseLeave: () => void;
  isHeaderMenuItemActive: boolean;
}

const HeaderMenuItemPopper: React.FC<Props> = (props: Props) => {
  const theme = useTheme();
  const { onMouseLeave, isHeaderMenuItemActive } = props;

  return (
    <Fade in={isHeaderMenuItemActive} timeout={{enter: 500, exit: 500}}>
      <ul  className={styles.headerMenuItemPopper}>
        <li className={styles.headerMenuItemPopper__item}>
          <span
            className={`${styles.headerMenuItemPopper__link} ${styles.headerMenuItemPopper__link_first}`}
          >
            our offer
          </span>
          <img src={caret_right} alt="caret right" className={styles.headerMenuItemPopper__caretDown}/>
        </li>
        <li className={styles.headerMenuItemPopper__item}>
          <NavLink
            to='/players'
            className={styles.headerMenuItemPopper__link}
            activeStyle={{
              color: `${theme.palette.secondary.main}`,
            }}
            onClick={onMouseLeave}
          >
            players
          </NavLink>
        </li>
        <li className={styles.headerMenuItemPopper__item}>
          <NavLink
            to='/intermediaries'
            className={styles.headerMenuItemPopper__link}
            activeStyle={{
              color: `${theme.palette.secondary.main}`,
            }}
            onClick={onMouseLeave}
          >
            intermediaries
          </NavLink>
        </li>
      </ul>
    </Fade>
  )
}

export default HeaderMenuItemPopper;

