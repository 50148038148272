import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ReactComponent as ArrowForwardIcon } from '../../assets/ourNews/arrow.svg';
import { Grid, IconButton } from '@mui/material';

interface Props {
  title: string;
  content: string;
  pic: string;
}

const NewsCard = (props: Props) => {
  const { title, content, pic } = props;

  return (
    <Card
    raised={true}
    sx={{
      width: '100%',
      margin: '0 auto',
      height: 604,
      minWidth: 200,
      maxWidth: 392,
      p: 4,
      borderRadius: '16px',
      boxShadow: 'none',
      backgroundImage: `linear-gradient(180deg, rgba(0, 133, 255, 0) 40.4%, #0085FF 75.13%), url(${pic})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    }}
    >
      <Grid container direction="column" justifyContent="flex-end" height="100%">
        <Typography
          gutterBottom
          variant="h4"
          sx={{
            height: 'auto'
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            height: '17%',
            mb: 1,
            overflow: 'hidden',
          }}
        >
          {content}
        </Typography>
        <CardActions
          sx={{
            p: 0,
            justifyContent: 'space-between',
          }}
        >
          <Button
            disableFocusRipple
            sx={{
              p: 0,
              fontSize: '1rem',
              lineHeight: '1.125rem',
              fontStyle: 'normal',
            }}
          >
            read more
          </Button>
          <IconButton sx={{p: 0}}>
            <ArrowForwardIcon />
          </IconButton>
        </CardActions>
      </Grid>
    </Card>
  )
}

export default NewsCard;
