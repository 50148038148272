import React, { ChangeEvent, useState } from 'react';
import { Alert, Box, Button, Container, Grid, Snackbar, TextField, Typography, useTheme } from '@mui/material';

import { textInputStyle } from '../../../styles/textInputStyle';

import './ContactUsForm.scss';

import { sendContactUsData } from '../../../utils/api';
import { emailRegEx } from '../../../utils/emailRegEx';
import { ContactUsResponse } from '../../../types/contactUsResponse';

interface Props {
  
}

const ContactUsForm = (props: Props) => {
  const [firstName, setFirstName] = useState<string>('');
  const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const [secondName, setSecondName] = useState<string>('');
  const [secondNameError, setSecondNameError] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');
  const [commentError, setCommentError] = useState<boolean>(false);

  const [open, setOpen] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const theme = useTheme();

  const firstNameHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
    setFirstNameError(false);
  };

  const secondNameHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSecondName(event.target.value);
    setSecondNameError(false);
  };

  const emailHandler = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setEmail(event.target.value);
    setEmailError(false);
  }

  const commentHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
    setCommentError(false);
  };

  const contactUsFormHandler = async (
    firstName: string,
    secondName: string,
    email: string,
    comment: string
  ) => {
    let isError = false;

    if (firstName.trim().length === 0) {
      setFirstNameError(true);
      isError = true;
    }

    if (secondName.trim().length === 0) {
      setSecondNameError(true);
      isError = true;
    }
    
    if (!emailRegEx.test(email)) {
      setEmailError(true);
      isError = true;
    }

    if (comment.trim().length === 0) {
      setCommentError(true);
      isError = true;
    }

    if (!isError) {
      let response: ContactUsResponse = await sendContactUsData(firstName, secondName, email, comment);
    
      if (typeof response === 'string') {
        setErrorMessage(response);
        setOpen(true);

        return;
      }

      if (response.error) {
        setErrorMessage(response.error);
      } else {
        setErrorMessage("The message has been sent!");
        setIsSubscribed(true);
      }

      setOpen(true);
    }
  }

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const sectionItemStyle = {
    mb: '40px',
  }

  const styles = {
    sectionItemStyle: {
      mb: '40px',
    },
    muiOutlinedInput: {
      lineHeight: '1.125rem',
      height: 'fit-content',
      border: '2px solid #AAAAAA',
    },
  }

  return (
    <Container>
      <Grid container justifyContent="center">
        <Grid item >
          <Typography variant="h2" align="center" sx={{...sectionItemStyle}}>
            contact us
          </Typography>
          <Box sx={{...styles.sectionItemStyle}}>
            <TextField
              required={true}
              error={firstNameError}
              helperText={firstNameError ? 'Invalid name' : ''}
              type="text"
              variant="outlined"
              fullWidth
              hiddenLabel={true}
              placeholder="* First Name"
              value={firstName}
              onChange={firstNameHandler}
              sx={{
                '.MuiOutlinedInput-input': {
                  ...textInputStyle['.MuiOutlinedInput-input'],
                  ...styles.muiOutlinedInput,
                },
                '.MuiFormHelperText-root': {
                  ...theme.typography.body2,
                  textTransform: 'none',
                  position: 'absolute',
                  top: '104%',
                  width: '100%',
                  height: 'fit-content',
                }
              }}
            />
          </Box>
          <Box sx={{...sectionItemStyle}}>
            <TextField
              required={true}
              error={secondNameError}
              helperText={secondNameError ? 'Invalid name' : ''}
              type="text"
              variant="outlined"
              fullWidth
              hiddenLabel={true}
              placeholder="* Second Name"
              value={secondName}
              onChange={secondNameHandler}
              sx={{
                '.MuiOutlinedInput-input': {
                  ...textInputStyle['.MuiOutlinedInput-input'],
                  ...styles.muiOutlinedInput,
                },
                '.MuiFormHelperText-root': {
                  ...theme.typography.body2,
                  textTransform: 'none',
                  position: 'absolute',
                  top: '104%',
                  width: '100%',
                  height: 'fit-content',
                }
              }}
            />
          </Box>
          <Box sx={{...sectionItemStyle}}>
            <TextField
              required={true}
              error={emailError}
              helperText={emailError ? 'Invalid Email' : ''}
              type="text"
              variant="outlined"
              fullWidth
              hiddenLabel={true}
              placeholder="* Email"
              value={email}
              onChange={emailHandler}
              sx={{
                '.MuiOutlinedInput-input': {
                  ...textInputStyle['.MuiOutlinedInput-input'],
                  ...styles.muiOutlinedInput,
                },
                '.MuiFormHelperText-root': {
                  ...theme.typography.body2,
                  textTransform: 'none',
                  position: 'absolute',
                  top: '104%',
                  width: '100%',
                  height: 'fit-content',
                }
              }}
            />
          </Box>
          <Box sx={{...sectionItemStyle}}>
            <TextField
              required={true}
              type="text"
              error={commentError}
              helperText={commentError ? 'The message is required!' : ''}
              multiline
              minRows="4"
              variant="outlined"
              fullWidth
              hiddenLabel={true}
              placeholder="* Your message"
              value={comment}
              onChange={commentHandler}
              inputProps={{ style: {resize: "vertical"}, className: "resizer" }}
              sx={{
                '.MuiOutlinedInput-root': {
                  p: 0,
                },
                '.MuiOutlinedInput-input': {
                  ...textInputStyle['.MuiOutlinedInput-input'],
                  border: '2px solid #AAAAAA',
                  pl: '16px',
                  lineHeight: '1rem'
                },
                '.MuiFormHelperText-root': {
                  ...theme.typography.body2,
                  textTransform: 'none',
                  position: 'absolute',
                  top: '104%',
                  width: '100%',
                  height: 'fit-content',
                }
              }}
            />
          </Box>
          <Box sx={{width: '50%'}}>
            <Button
              variant="contained"
              fullWidth={true}
              onClick={() => contactUsFormHandler(firstName, secondName, email, comment)}
              sx={{px: '36px'}}
            >
              send
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={isSubscribed ? 'success' : 'error'} sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  )
}

export default ContactUsForm;
