import { ThemeProvider } from '@mui/material/styles';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { Colors } from '../types/colors';

type Props = {
  children?: React.ReactNode;
};

let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 1288,
      xl: 1536,
    }
  },
  palette: {
    mode: 'dark',
    primary: {
      main: `${Colors.yellow}`,
    },
    secondary: {
      main: `${Colors.red}`,
    },
    text: {
      primary: '#fff',
      secondary: '#111',
    },
    background: {
      default: `${Colors.black}`,
    },
  },
  typography: {
    h1: {
      fontFamily: "'Hoxton800italic', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 800,
      fontStyle: 'italic',
      fontSize: '4rem', // 64px (base: 16px)
      lineHeight:  4.875,
      letterSpacing: '0.21em',
    },
    h2: {
      fontFamily: "'Hoxton800italic', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 800,
      fontStyle: 'italic',
      fontSize: '3rem', // 48px (base: 16px)
      lineHeight: 1,
      letterSpacing: '0.05em',
    },
    h3: {
      fontFamily: "'Hoxton800italic', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 800,
      fontStyle: 'italic',
      fontSize: '2.25rem', // 36px (base: 16px)
      lineHeight: 1,
      letterSpacing: '0.05em',
      textTransform: 'uppercase',
    },
    h4: {
      fontFamily: "'Hoxton800italic', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 800,
      fontStyle: 'italic',
      fontSize: '1.5rem', // 24px (base: 16px)
      lineHeight: 1,
      letterSpacing: '0.05em',
      textTransform: 'uppercase',
    },
    h5: {
      fontFamily: "'Hoxton800italic', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 800,
      fontStyle: 'italic',
      fontSize: '1.125rem', // 18px (base: 16px)
      lineHeight: 1,
      letterSpacing: '0.05em',
      textTransform: 'uppercase',
    },
    h6: {
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 900,
      fontStyle: 'italic',
      fontSize: '3rem', // 48px (base: 16px)
      lineHeight: 0.857,
      letterSpacing: '0.05em',
      textTransform: 'uppercase',
    },
    subtitle1: {
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 700,
      fontStyle: 'italic',
      fontSize: '1.5rem', // 24px (base: 16px)
      lineHeight: 0.820,
      letterSpacing: '0.21em',
      textTransform: 'uppercase',
    },
    subtitle2: {
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 700,
      fontStyle: 'italic',
      fontSize: '1.125rem', // 18px (base: 16px)
      lineHeight: 1.313,
      letterSpacing: '0.05em',
      textTransform: 'uppercase',
    },
    body1: {
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '1.125rem', // 18px (base: 16px)
      lineHeight: 1.313,
      letterSpacing: '0.05em',
    },
    body2: {
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '0.875rem', // 14px (base: 16px)
      lineHeight: 1,
      letterSpacing: '0.05em',
    },
    button: {
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 900,
      fontStyle: 'italic',
      fontSize: '1rem', // 16px (base: 16px)
      lineHeight: 1.125,
      letterSpacing: '0.09em',
      textTransform: 'uppercase',
    },
    caption: {
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 700,
      fontSize: '1.5rem', // 24px (base: 16px)
      lineHeight: 0.857,
      letterSpacing: '0.05em',
      textTransform: 'uppercase',
    },
  },
  mixins: {
    toolbar: {
      minHeight: 0,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        }
      },
      defaultProps: {
        color: 'transparent',
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '16px 0',
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: '1.6rem',
        }
      },
    },
  }
});

theme = responsiveFontSizes(theme);

export const MyThemeProvider: React.FC<Props> = (props) => {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}
