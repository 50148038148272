import React from 'react';
import { Container } from '@mui/material';
import SectionTitle from '../UI/SectionTitle';
import { AccordionItemType } from '../../types/accordionItem';
import AccordionItem from './AccordionItem';
import { Box } from '@mui/system';

interface Props {
  
}

const FAQ = (props: Props) => {
  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  const accordionItems: AccordionItemType[] = [
    {
      order: 'panel1',
      title: 'How do i start?',
      content: 'For the moment, the platform is still under development. If you are interested to keep updated and want to jump in is as soon as we are live, you are in the right place. Just sign up above for the newsletter, and you will receive the fresh news, right in your inbox.'
    },
    {
      order: 'panel2',
      title: 'How do we pay back everyone?',
    },
    {
      order: 'panel3',
      title: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit?',
    },
    {
      order: 'panel4',
      title: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit?',
    },
    {
      order: 'panel5',
      title: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit?',
    },
  ]

  return (
    <section>
      <Container>
        <SectionTitle text="faq" />
        <Box sx={{mb: {xs: 0, sm: 10}}}>
          {accordionItems.map((accordionItem: AccordionItemType) => (
            <AccordionItem
              content={accordionItem}
              state={expanded}
              switcher={setExpanded}
              key={accordionItem.order}
            />
          ))}
        </Box>
      </Container>
    </section>
  )
}

export default FAQ;
