import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import SectionTitle from '../UI/SectionTitle';
import { Box } from '@mui/system';

import crowd from '../../assets/becomePro/crowd.jpg';
import mbappe from '../../assets/becomePro/mbappeCropped.png';

interface Props {
  
}

const BecomePro = (props: Props) => {
  const paragraphStyle = {
    mb: '20px',
  };

  return (
    <section>
      <Container>
        <SectionTitle text="the fastest path to becoming pro" />
        <Grid
          container
          justifyContent="center"
          sx={{
            my: '80px'
          }}
        >
          <Grid item md={10}>
            <Grid container columns={2} columnSpacing={4} rowSpacing={4}>
              <Grid
                item
                sm={1}
                container
                direction="column"
                justifyContent="space-between"
              >
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      ...paragraphStyle,
                    }}
                  >
                    Have an intermediary create a profile for you on our platform, and fill in a professionnal datasheet, just like the pros. Upload videos of you playing. Show off your skills!
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      ...paragraphStyle,
                    }}
                  >
                    Get evaluated and rated, and progress through the rankings.
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      ...paragraphStyle,
                    }}
                  >
                    Get better through the years, and get your own NFT that represents you.
                  </Typography>
                </Box>
                <img src={crowd} alt="crowd at stadium" width="100%" />
              </Grid>
              <Grid
                item
                sm={1}
                sx={{
                  alignItems: 'flex-end',
                  display: 'flex',
                }}
              >
                <Box
                  sx={{
                    maxWidth: '498px',
                    display: 'flex',
                    alignItems: 'flex-end',
                  }}
                >
                  <img src={mbappe} alt="Kylian Mbappé" width="100%"/>
                </Box>
              </Grid>
              <Grid item sm={1}>
                <Typography
                  variant="body1"
                  sx={{
                    ...paragraphStyle,
                  }}
                >
                    Experts will come to your games to see you play. Impress them, and investors will start funding your career.
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    ...paragraphStyle,
                  }}
                >
                    
Through this money, join better and better teams, and rise among your peers.
                </Typography>
              </Grid>
              <Grid item sm={1}>
                <Typography
                  variant="body1"
                  sx={{
                    ...paragraphStyle,
                  }}
                >
                  The end goal is to come play in Europe. You will be a shining star, playing for the biggest clubs.
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    ...paragraphStyle,
                  }}
                >
                  Through the funding, you will get opportunity to demo for the top teams, and get help for negociating your contract.
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    ...paragraphStyle,
                  }}
                >
                  Congratulations, you just became a professionnal football player!
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </section>
  )
}

export default BecomePro;
