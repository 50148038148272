import React from "react";
import { Container } from "@mui/material";
import Slider from "react-slick";
import './OurPartners.scss';
import SionLogo from '../../assets/ourPartners/image_0.png';
import AmiensLogo from '../../assets/ourPartners/image_1.png';
import MillwallLogo from '../../assets/ourPartners/image_2.png';
import KolnLogo from '../../assets/ourPartners/image_3.png';
import SportingLogo from '../../assets/ourPartners/image_4.png';
import MattersburgLogo from '../../assets/ourPartners/image_5.png';
import SectionTitle from "../UI/SectionTitle";

interface Props {
  
}

const OurPartners = (props: Props) => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5.715,
    slidesToScroll: 2,
    variableWidth: true,
  };

  return (
    <section>
      <Container>
        <SectionTitle text="our partners" />
        <Slider {...settings} className="ourPartnersSlider">
          <div>
            <img src={SionLogo} alt="" width="131px" height="131px" />
          </div>
          <div>
            <img src={AmiensLogo} alt="" width="131px" height="131px" />
          </div>
          <div>
            <img src={MillwallLogo} alt="" width="131px" height="131px" />
          </div>
          <div>
            <img src={KolnLogo} alt="" width="131px" height="131px" />
          </div>
          <div>
            <img src={SportingLogo} alt="" width="131px" height="131px" />
          </div>
          <div>
            <img src={MattersburgLogo} alt="" width="131px" height="131px" />
          </div>
        </Slider>
      </Container>
    </section>
  )
}

export default OurPartners;
