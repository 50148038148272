import React from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { Colors } from '../../types/colors';

interface Props {
  picPath: string;
  alt: string;
  title: string | React.ReactNode;
  subTitle: string;
}

const OurMissionInNumbersItem = (props: Props) => {
  const { picPath, alt, title, subTitle } = props;

  return (
    <Grid
      container
      item
      xs={12}
      sm
      md={5}
      lg
      direction="column"
      justifyContent="flex-end"
      alignItems="center"
      sx={{
        px: 2,
        pb: 2.6,
        height: '286px',
        minWidth: '274px',
        border: `1px solid ${Colors.yellow}`,
        borderRadius: 4,
      }}
    >
      <img src={picPath} alt={alt} width="100px" height="100px" />
      <Divider
        sx={{
          color: `${Colors.yellow}`,
          borderBottom: `1px solid ${Colors.yellow}`,
          mt: '14px',
          width: '74%'
        }}
      />
      <Typography
        align="center"
        sx={{
          fontFamily: "'Hoxton800italic', 'Roboto', 'Helvetica', 'Arial', sans-serif",
          fontWeight: 800,
          fontStyle: 'italic',
          letterSpacing: '0.05em',
          '&.MuiTypography-root': {
            fontSize: '4rem', // 64px (base: 16px)
            lineHeight: '4.125rem',
          }
        }}
      >
        {title}
      </Typography>
      <Typography
        align="center"
        noWrap
        sx={{
          fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
          fontWeight: 700,
          fontStyle: 'normal',
          fontSize: '1.125rem', // 18px (base: 16px)
          lineHeight: '1.313rem',
          letterSpacing: '0.05em',
          textTransform: 'uppercase',
        }}
      >
        {subTitle}
      </Typography>
    </Grid>
  )
}

export default OurMissionInNumbersItem;
