import React from 'react';
import Case from '../../types/case';
import { Box, Card, CardContent, CardMedia, Typography, useTheme } from '@mui/material';
import logo from '../../assets/logo_white.svg';

interface Props {
  props: Case;
}

const OurCase = (props: Props) => {
  const {pic, gradient, fullName, role} = props.props;
  const theme = useTheme();

  return (
    <Card sx={{
      maxWidth: '100%',
      minWidth: '290px',
      p: '20px',
      borderRadius: '16px',
      backgroundImage: gradient,
      [theme.breakpoints.down('sm')]: {
        minWidth: '0px',
      },
    }}>
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <CardMedia
          component="img"
          alt="Didier Drogba"
          image={pic}
          sx={{
            maxWidth: '100%',
            border: '2px solid #FFFFFF',
          }}
        />
        <img
          src={logo}
          alt="the Preesm logo"
          width="89px"
          style={{
            position: 'absolute',
            top: '13px',
            left: '9px',
          }}
        />
      </Box>
      <CardContent
        sx={{
          px: 0,
          pt: '20px',
          '&.MuiCardContent-root:last-child': {
            pb: 0,
          },
        }}
      >
        <Typography variant="h3"
          sx={{
            mb: '10px',
          }}
        >
          {fullName.firstName}<br></br>{fullName.lastName}
        </Typography>
        <Typography variant="subtitle2">
          {role}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default OurCase;
