import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { SubscribeForm } from './SubscribeForm';

interface Props {
  title: string;
  subtitle: string;
}

const HeaderWithForm = (props: Props) => {
  const { title, subtitle } = props;

  //if the title is too long, it adds padding-left where needed
  let customStyle = {
    width: 'fit-content',
    mb: '38px',
    pl: {lg: 'none'}
  }

  if (title.length > 15) {
    customStyle.pl = {lg: '6%'};
  }
  //

  return (
    <Container sx={{height: '100%'}}>
      <Grid container alignItems={{xs: 'flex-end', sm: 'center'}} justifyContent="center" sx={{height: '100%', pt: '100px'}}>
        <Grid item md={8} sx={{mb: {xs: '48%', sm: '0px'}}}>
          <Grid container justifyContent="center">
            <Grid item sx={{...customStyle}}>
              <Typography
                variant="h1"
                sx={{
                  letterSpacing: '0.05em',
                  lineHeight: '100%',
                  mb: '15px'
                }}
              >
                {title}
              </Typography>
              <Typography variant="subtitle2"
                sx={{
                  fontSize: '1rem',
                  lineHeight: '1.125rem',
                  letterSpacing: 'none',
                  textTransform: 'uppercase',
                  '&.MuiTypography-root': {
                    fontSize: '1rem',
                  }
                }}
              >
                {subtitle}
              </Typography>
            </Grid>
            <SubscribeForm justifyContent="center" />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default HeaderWithForm;
