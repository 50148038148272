import React from 'react';
import Header from '../components/UI/Header';
import PreesmAppBar from '../components/UI/PreesmAppBar';

import bgImg from '../assets/header/about_us_header_bg.jpg';
import Footer from '../components/Footer/Footer';
import HeaderTitle from '../components/UI/HeaderTitle';
import OurValues from '../components/OurValues/OurValues';
import WhoWeAre from '../components/WhoWeAre/WhoWeAre';
import ContactUs from '../components/ContactUs/ContactUs';
import OurNews from '../components/OurNews/OurNews';
import FAQ from '../components/FAQ/FAQ';
import Subscribe from '../components/UI/Subscribe/Subscribe';
import { Colors } from '../types/colors';
import { Link } from '@mui/material';

export const AboutUs: React.FC = () => {
  return (
    <>
      <Header bgImg={bgImg}>
        <PreesmAppBar />
        <HeaderTitle content="about us" />
      </Header>
      <main>
        <OurValues />
        <WhoWeAre />
        <Link id="contact_us" sx={{textDecoration: 'none', color: 'text.primary'}}>
          <ContactUs />
        </Link>
        <OurNews />
        <FAQ />
        <Subscribe color={Colors.blue} />
      </main>
      <Footer />
    </>
  );
}
