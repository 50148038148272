import React from 'react';
import Link from '@mui/material/Link';
import { NavLink } from 'react-router-dom';

interface Props {
  content: string;
  path: string;
}

const FooterNavLink = (props: Props) => {
  const { content, path } = props;

  return (
    <Link
      underline="none"
      to={path}
      component={NavLink}
      activeStyle={{
        textDecoration: 'underline',
      }}
      sx={{
        mb: 3,
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontWeight: 700,
        fontStyle: 'normal',
        fontSize: '0.875rem', // 14px (base: 16px)
        lineHeight: '1rem',
        letterSpacing: '0.05em',
        color: 'text.primary',
        textAlign: {md: 'left'},
      }}
    >
      {content}
    </Link>
  )
}

export default FooterNavLink;
