const url = 'https://preesm.5w155.ch/actions.php';

export const sendSubscribtionData = async (email: string, role: string) => {
  const formData = new FormData();

  formData.append('type', 'subscribe');
  formData.append('email', email);
  formData.append('userType', role);

  const response = await fetch(url, {
    method: 'POST',
    body: formData
  });

  return (
    response.ok ?
      response.json() :
      `${response.status}: ${response.statusText}`
  );
}

export const sendContactUsData = async (
  firstName: string,
  lastName: string,
  email: string,
  comment: string
  ) => {
  const formData = new FormData();

  formData.append('type', 'contact');
  formData.append('email', email);
  formData.append('firstName', firstName);
  formData.append('lastName', lastName);
  formData.append('msg', comment);

  const response = await fetch(url, {
    method: 'POST',
    body: formData
  });

  return (
    response.ok ?
      response.json() :
      `${response.status}: ${response.statusText}`
  );
}
