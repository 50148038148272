import React from 'react';
import { CssBaseline } from '@mui/material';
import { Switch, Route, Redirect} from 'react-router-dom';
import './App.css';
import { AboutUs } from './pages/AboutUs';
import { Home } from './pages/Home';
import { Intermediaries } from './pages/Intermediaries';
import { Players } from './pages/Players';
import { OurNewsPage } from './pages/OurNewsPage';
import BreathtakingArticle from './pages/BreathtakingArticle';
import { OurCases } from './pages/OurCases';
import DidierDrogba from './pages/DidierDrogba';
import ScrollToTop from './utils/ScrollToTop';
import { ContactUsPage } from './pages/ContactUsPage';

function App() {
  return (
    <>
      <CssBaseline />
      <ScrollToTop />
      <Switch>
        <Route path="/" exact>
          <Redirect to="/home" />
        </Route>
        <Route path="/home">
          <Home />
        </Route>
        <Route path="/players">
          <Players />
        </Route>
        <Route path="/intermediaries">
          <Intermediaries />
        </Route>
        <Route path="/about_us">
          <AboutUs />
        </Route>
        <Route path="/our_news">
          <OurNewsPage />
        </Route>
        <Route path="/breathtaking_article">
          <BreathtakingArticle />
        </Route>
        <Route path="/our_cases" exact>
          <OurCases />
        </Route>
        <Route path="/our_cases/didier_drogba">
          <DidierDrogba />
        </Route>
        <Route path="/contact_us">
          <ContactUsPage />
        </Route>
      </Switch>
    </>
  );
}

export default App;
