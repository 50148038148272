import React from 'react';
import { Button, Container, Grid } from '@mui/material';
import SectionTitle from '../UI/SectionTitle';
import newCardBg_1 from '../../assets/ourNews/newCardBg_1.png';
import newCardBg_2 from '../../assets/ourNews/newCardBg_2.png';
import newCardBg_3 from '../../assets/ourNews/newCardBg_3.png';
import NewsCard from '../UI/NewsCard';
import { Link } from 'react-router-dom';

interface Props {
  withButton?: boolean;
}

interface News {
  title: string,
  content: string,
  pic: string;
}

const news: News[] = [
  {
    title: 'New signing',
    content: 'We are proud to announce that a player signed a professionnal contract through our platform. This represents the culmination...',
    pic: newCardBg_1,
  },
  {
    title: 'A new stadium built in africa',
    content: 'Thanks to Preesm’s giveback revenue model, money has been gathered and a new stadium has been built in Africa. A young...',
    pic: newCardBg_2,
  },
  {
    title: 'New scouts',
    content: 'Through our constant recruiting of scouts, we increase the quality and quantity of intermediaries in the market.',
    pic: newCardBg_3,
  },
];

const OurNews = (props: Props) => {
  const {withButton = true} = props;

  return (
    <section>
      <Container sx={{mb: {xs: '30px', md: '120px'}}}>
        <SectionTitle text="our news" />
        <Grid container gap="32px" justifyContent="center" sx={{mb: {xs: 4, sm: 5}}}>
          {news.map((piece: News) => (
            <Grid
              item
              key={piece.content}
              sm={8}
              md
            >
              <NewsCard
                title={piece.title}
                content={piece.content}
                pic={piece.pic}
              />
            </Grid>
          ))}
        </Grid>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={4} md={3} lg={2} sx={{maxWidth: 392}}>
            {withButton && (
              <Link to="/our_news" style={{textDecoration: 'none'}}>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{px: '36px'}}
                >
                  all news
                </Button>
              </Link>
            )}
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

export default OurNews;
