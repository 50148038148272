import React from 'react';
import Header from '../components/UI/Header';
import PreesmAppBar from '../components/UI/PreesmAppBar';

import bgImg from '../assets/header/players_header_bg.jpg';
import HeaderTitle from '../components/UI/HeaderTitle';
import BecomePro from '../components/BecomePro/BecomePro';
import OurRole from '../components/OurRole/OurRole';
import Subscribe from '../components/UI/Subscribe/Subscribe';
import { Colors } from '../types/colors';
import OurMissionInNumbers from '../components/OurMissionInNumbers/OurMissionInNumbers';
import OurPartners from '../components/OurPartners/OurPartners';
import FAQ from '../components/FAQ/FAQ';
import Footer from '../components/Footer/Footer';
import OurCasesSwipe from '../components/OurCases/OurCasesSwipe';
import OurNews from '../components/OurNews/OurNews';

export const Players: React.FC = () => {
  return (
    <>
      <Header bgImg={bgImg}>
        <PreesmAppBar />
        <HeaderTitle content="OUR offer for Players" />
      </Header>
      <main>
        <BecomePro />
        <OurRole />
        <Subscribe color={Colors.red} />
        <OurCasesSwipe />
        <OurMissionInNumbers />
        <OurPartners />
        <OurNews />
        <FAQ />
        <Subscribe color={Colors.red} />
      </main>
      <Footer />
    </>
  );
}
