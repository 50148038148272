import React from 'react';
import { Box, Container, Grid, Typography, useTheme } from '@mui/material';
import YellowNumber from '../UI/YellowNumber';

interface Props {
  
}

const roles: string[] = [
  'We help you get detected, wherever you live.',
  'Through our ranking system, we bring your talents to light.',
  'Once you have established your skills, we create an nft of you.',
  'We contact investors that are interested in you, and make them invest in you.',
  'We get you a career funding, as well as opportunities to go to camps and formations to hone your skills.',
  'We make the bridge between you and the teams, and help you sign a professionnal contract.'
]

const OurRole = (props: Props) => {
  const theme = useTheme();

  return (
    <section>
      <Container>
        <Box
          sx={{
            position: 'relative',
            p: {xs: '20px'},
            py: {xs: '80px', md: '60px', lg: '72px'},
            mb: {xs: '70px', md: '120px'},
            border: '1px solid #fff',
            borderRadius: '33px',
            ':before': {
              content: '"our role in your career"',
              position: 'absolute',
              top: '-4%',
              left: '0%',
              right: '0%',
              px: '5%',
              margin: '0 auto',
              width: 'fit-content',
              bgcolor: 'background.default',
              textAlign: 'center',
              ...theme.typography.h2,
              [theme.breakpoints.down('lg')]: {
                px: '2%',
              },
              [theme.breakpoints.down('md')]: {
                fontSize: '32px',
                px: '20px',
                width: '70%',
              },
              [theme.breakpoints.down('sm')]: {
                top: '-4%',
              },
              [theme.breakpoints.down(374)]: {
                top: '-6%',
              }
            }
          }}
        >
          <Grid container justifyContent="center">
            <Grid item md={10}>
              <Grid
                container
                columns={3}
                rowSpacing={{ xs: '50px', md: '100px', lg: '144px'}}
                columnSpacing={{ xs: '50px', md: '75px', lg: '85px' }}
              >
                {roles.map((role: string, i: number) => (
                  <Grid item md={1} key={role}>
                    <YellowNumber number={i + 1} fontSizePx="48px" />
                    <Typography
                      variant="body1"
                      sx={{
                        pt: {xs: '10px', lg: '32px'}
                      }}
                    >
                      {role}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </section>
  )
}

export default OurRole;
