import { Box } from '@mui/material';
import React from 'react'
import styles from './Header.module.scss';

interface Props {
  children?: React.ReactNode;
  bgImg: string;
}

const Header: React.FC<Props> = (props: Props) => {
  const { bgImg } = props;

  const headerBackground = () => `linear-gradient(180deg, #0F1521 10%, rgba(0, 0, 0, 0) 24%, #0F1521 100%), url(${bgImg})`;

  return (
    <Box
      className={styles.header}
      sx={{
        backgroundImage: headerBackground(),
        minHeight: '520px'}}
      component="header"
    >
      {props.children}
    </Box>
  )
}

export default Header
