import React from 'react';
import { Container, Grid } from '@mui/material';
import contactUsBg from '../assets/contactUs/contactUsBg.jpg';
import Footer from '../components/Footer/Footer';
import ContactUsForm from '../components/UI/ContactUsForm/ContactUsForm';
import Header from '../components/UI/Header';
import PreesmAppBar from '../components/UI/PreesmAppBar';


interface Props {
  
}

export const ContactUsPage = (props: Props) => {
  return (
    <>
      <Header bgImg={contactUsBg}>
        <PreesmAppBar />
        <Container sx={{height: '100%', py: '80px'}}>
          <Grid container alignItems="center" justifyContent="center" sx={{height: '100%'}}>
            <ContactUsForm />
          </Grid>
        </Container>
      </Header>
      <Footer />
    </>
  );
}
