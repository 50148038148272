export const textInputStyle = {
  '.MuiOutlinedInput-input': {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontWeight: 400,
    fontSize: '1rem',
    color: 'text.secondary',
    bgcolor: 'text.primary',
    borderRadius: '4px',
    py: 2,
    '::-webkit-input-placeholder': {
      color: 'text.secondary',
      opacity: '1',
    },
  },
}
