import React from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { Colors } from '../../types/colors';

import playerSilhouette from '../../assets/whatWeDo/playerSilhouette.svg';
import cryptoCoins from '../../assets/whatWeDo/cryptoCoins.svg';
import investments from '../../assets/whatWeDo/investments.svg';
import dollarCoin from '../../assets/whatWeDo/dollarCoin.svg';
import superCup from '../../assets/whatWeDo/superCup.svg';

interface Props {
  
}

const WhatWeDoGraph = (props: Props) => {
  const theme = useTheme();

  const styles = {
    deedTitle: {
      position: 'absolute',
      top: '0px',
      left: '0px',
      width: '50%',
      transform: 'translateY(-40px)',
      ':before': {
        content: '"1"',
        position: 'absolute',
        bottom: '0px',
        left: '0px',
        transform: 'translate(-120%, 10px)',
        fontFamily: "'Hoxton800italic', 'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontWeight: 800,
        fontStyle: 'italic',
        fontSize: '6rem', // 96px (base: 16px)
        lineHeight: '100%',
        letterSpacing: '0.05em',
        color: 'primary.main'
      }
    },
    deedTitleAfter: {
      ...theme.typography.body2,
      textTransform: 'none',
      position: 'absolute',
      top: '40px',
      left: '0px',
    }
  } as const;

  return (
    <>
      <Grid
        container
        columns={14}
        alignItems="flex-end"
        sx={{
          pt: '80px'
        }}
      >
        <Grid
          item
          md
          lg={2}
          sx={{
            position: 'relative',
            height: '236px',
            borderRight: '2px solid white',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              right: '0%',
              bottom: '0%',
              transform: 'translate(50%, 50%)',
              width: '18px',
              height: '18px',
              bgcolor: 'white',
              borderRadius: '9px'
            }}
          ></Box>
        </Grid>
        <Grid
          item
          md
          lg={4}
          sx={{height: '236px', position: 'relative'}}
        >
          <Box
            sx={{
              position: 'absolute',
              right: '0%',
              bottom: '0%',
              transform: 'translate(50%, 50%)',
              width: '18px',
              height: '18px',
              bgcolor: 'white',
              borderRadius: '9px'
            }}
          ></Box>
          <Box
            sx={{
              position: 'absolute',
              bottom: '0%',
              left: '50%',
              transform: 'translate(-46%, 15%)',
            }}
          >
            <img src={playerSilhouette} alt="football player silhouette" />
          </Box>
          <Typography
            variant="h5"
            sx={{
              ...styles.deedTitle,
              top: '-20px',
              [theme.breakpoints.between('md', 'lg')]: {
                ':before': {
                  transform: 'translate(-140%, 50%)',
                }
              },
            }}
          >
            Player insertion
          </Typography>
          <Typography
            variant="body2"
            sx={{
              width: '60%',
              pl: '5%',
              [theme.breakpoints.between('md', 'lg')]: {
                width: '90%',
              },
            }}
          >
            An intermediary creates the player’s profile, enters data on a professional datasheet, and provides videos showing the skills of the player.
          </Typography>
        </Grid>
        <Grid
          item
          md
          lg={4}
          sx={{
            position: 'relative',
            height: '364px',
            borderLeft: '2px solid white'
          }}
        >
          <Typography
            variant="h5"
            sx={{
              ...styles.deedTitle,
              '&:before': {
                content: '"3"',
              },
              [theme.breakpoints.between('md', 'lg')]: {
                ':before': {
                  transform: 'translate(-140%, 66%)',
                }
              },
            }}
          >
            Tokenisation
          </Typography>
          <Typography
            variant="body2"
            sx={{
              width: '60%',
              pl: '5%',
              [theme.breakpoints.between('md', 'lg')]: {
                width: '100%',
              },
            }}
          >
            For the top players, we create fractionnal NFTs representing them and their career. This allows to tokenize the value of players.
          </Typography>
          <Box
            sx={{
              position: 'absolute',
              right: '0%',
              bottom: '0%',
              transform: 'translate(50%, 50%)',
              width: '18px',
              height: '18px',
              bgcolor: 'white',
              borderRadius: '9px'
            }}
          ></Box>
          <Box
            sx={{
              position: 'absolute',
              bottom: '7%',
              left: '50%',
              transform: 'translate(-46%)',
            }}
          >
            <img src={investments} alt="coin, dollar sign, and some graphs" />
          </Box>
        </Grid>
        <Grid
          item
          md
          lg={4}
          sx={{
            position: 'relative',
            height: '236px',
            borderLeft: '2px solid white'
          }}
        >
          <Typography
            variant="h5"
            sx={{
              ...styles.deedTitle,
              '&:before': {
                content: '"5"',
              },
              [theme.breakpoints.between('md', 'lg')]: {
                ':before': {
                  transform: 'translate(-140%, 68%)',
                }
              },
            }}
          >
            Funding
          </Typography>
          <Typography
            variant="body2"
            sx={{
              width: '60%',
              pl: '5%',
              [theme.breakpoints.between('md', 'lg')]: {
                width: '90%',
              },
            }}
          >
            Both from the money poured into his NFT, and from our support, a player can win career funding.
          </Typography>
          <Box
            sx={{
              position: 'absolute',
              bottom: '10%',
              left: '50%',
              transform: 'translate(-50%)',
            }}
          >
            <img src={superCup} alt="coin, dollar sign, and some graphs" />
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        columns={14}
        sx={{
          position: 'relative',
          pb: '130px',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '0%',
            left: '50%',
            width: '90%',
            height: '120px',
            bgcolor: Colors.blue,
            transform: 'translate(-50%, -50%) skew(-20deg, 0deg)',
            zIndex: -1,
          }}
        ></Box>
        <Grid
          item
          md
          lg={4}
          sx={{
            position: 'relative',
            height: '236px',
            borderTop: '2px solid white',
            borderRight: '2px solid white'
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              right: '0%',
              top: '0%',
              transform: 'translate(50%, -50%)',
              width: '24px',
              height: '24px',
              bgcolor: 'white',
              borderRadius: '12px'
            }}
          ></Box>
        </Grid>
        <Grid
          item
          md
          lg={4}
          sx={{
            position: 'relative',
            height: '236px',
            borderTop: '2px solid white',
          }}
        >
          <Typography
            variant="h5"
            sx={{
              ...styles.deedTitle,
              top: '128%',
              '&:before': {
                content: '"2"',
              },
              '&:after': {
                content: '"The player is evaluated by the network. His level of skill is represented by a number on a scale."',
                ...styles.deedTitleAfter,
              },
              [theme.breakpoints.between('md', 'lg')]: {
                width: '90%',
                ':before': {
                  transform: 'translate(-140%, 68%)',
                }
              },
            }}
          >
            Evaluation
          </Typography>
          <Box
            sx={{
              position: 'absolute',
              right: '0%',
              top: '0%',
              transform: 'translate(50%, -50%)',
              width: '18px',
              height: '18px',
              bgcolor: 'white',
              borderRadius: '9px'
            }}
          ></Box>
          <Box
            sx={{
              position: 'absolute',
              top: '14%',
              left: '50%',
              transform: 'translate(-50%)',
            }}
          >
            <img src={cryptoCoins} alt="crypto-coins" />
          </Box>
        </Grid>
        <Grid
          item
          md
          lg={4}
          sx={{
            position: 'relative',
            height: '130px',
            borderTop: '2px solid white',
            borderLeft: '2px solid white'
          }}
        >
          <Typography
            variant="h5"
            sx={{
              ...styles.deedTitle,
              top: '150%',
              '&:before': {
                content: '"4"',
              },
              '&:after': {
                content: '"Investors can buy fractions of the NFTs of players. The more they buy, the more they own."',
                ...styles.deedTitleAfter,
              },
              [theme.breakpoints.between('md', 'lg')]: {
                width: '90%',
                ':before': {
                  transform: 'translate(-130%, 68%)',
                }
              },
            }}
          >
            Investment
          </Typography>
          <Box
            sx={{
              position: 'absolute',
              right: '0%',
              top: '0%',
              transform: 'translate(50%, -50%)',
              width: '18px',
              height: '18px',
              bgcolor: 'white',
              borderRadius: '9px'
            }}
          ></Box>
          <Box
            sx={{
              position: 'absolute',
              top: '14%',
              left: '50%',
              transform: 'translate(-50%)',
            }}
          >
            <img src={dollarCoin} alt="crypto-coins" />
          </Box>
        </Grid>
        <Grid
          item
          md
          lg={2}
          sx={{
            height: '236px',
            borderTop: '2px solid white',
            borderLeft: '2px solid white',
            position: 'relative',
          }}
        >
          <Typography
            variant="h5"
            sx={{
              ...styles.deedTitle,
              top: '128%',
              width: '100%',
              '&:before': {
                content: '"6"',
              },
              '&:after': {
                content: '"Professional clubs are put in contact with the player, and a deal is signed. Congratulations!"',
                ...styles.deedTitleAfter,
              },
              [theme.breakpoints.between('md', 'lg')]: {
                width: '90%',
                top: '130%',
                ':before': {
                  transform: 'translate(-140%, 78%)',
                }
              },
            }}
          >
            turning pro
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default WhatWeDoGraph;
