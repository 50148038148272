import React from 'react';
import Header from '../components/UI/Header';
import PreesmAppBar from '../components/UI/PreesmAppBar';

import bgImg from '../assets/header/our_news_header_bg.jpg';
import HeaderWithForm from '../components/UI/HeaderWithForm';
import OurNews from '../components/OurNews/OurNews';
import Subscribe from '../components/UI/Subscribe/Subscribe';
import { Colors } from '../types/colors';
import Footer from '../components/Footer/Footer';


export const OurNewsPage: React.FC = () => {
  return (
    <>
      <Header bgImg={bgImg}>
        <PreesmAppBar />
        <HeaderWithForm title="our news" subtitle="Want to get news first? Subscribe our news" />
      </Header>
      <main>
        <OurNews withButton={false} />
        <Subscribe color={Colors.green} />
      </main>
      <Footer />
    </>
  )
}
