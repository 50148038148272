import React, { useState } from 'react';

import styles from './CustomSelect.module.scss';

import img from '../../../assets/header/caret_down_black.svg';
import { Fade } from '@mui/material';

interface Props {
  setRole: React.Dispatch<React.SetStateAction<any>>;
}

const CustomSelect: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [activeOption, setActiveOption] = useState<string>('Player');

  const { setRole } = props;

  const options = ['Player', 'Parent', 'Intermediary', 'Investor'];

  const optionsToRender = options
    .filter((option: string) => option !== activeOption)
    .sort((a: string, b: string) => a.localeCompare(b));

  const selectToggler = () => setIsOpen((currentState: boolean) => !currentState);

  const onSelect = (option: string) => {
    setActiveOption(option);
    setRole(option);
    setIsOpen(false);
  }

  return (
    <div className={styles.select} onMouseLeave={() => setIsOpen(false)} >
      <div
        className={`${styles.select__button} ${isOpen ? styles.select__button_noRadius : ''}`}
        onClick={selectToggler}
      >
        {activeOption}
        <img src={img} alt="" />
      </div>
      {isOpen && (<div className={styles.select__content}>
        {optionsToRender.map((option: string) => (
          <Fade in={isOpen} timeout={{enter: 500, exit: 500}} key={option}>
            <div
              className={styles.select__item}
              onClick={() => onSelect(option)}
            >
              {option}
            </div>
          </Fade>
        ))}
      </div>)}
    </div>
  )
}

export default CustomSelect;
