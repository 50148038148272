import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

interface Props {
  
}

const ScrollToTop = (props: Props) => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action !== 'POP') {
        window.scrollTo(0, 0)
      }
    })
    return () => unlisten()
  }, [history])

  return null;
}

export default ScrollToTop;

