import React from 'react';
import Header from '../components/UI/Header';
import PreesmAppBar from '../components/UI/PreesmAppBar';

import bgImg from '../assets/header/our_cases_header_bg.jpg';
import HeaderWithForm from '../components/UI/HeaderWithForm';
import OurCase from '../components/OurCases/OurCase';

import drogba from '../assets/ourCases/drogba.jpg';
import klose from '../assets/ourCases/klose.jpg';
import unknown from '../assets/ourCases/unknown.jpg';
import beckham from '../assets/ourCases/beckham.jpg';
import Case from '../types/case';
import { Container, Grid, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { Colors } from '../types/colors';
import Subscribe from '../components/UI/Subscribe/Subscribe';
import Footer from '../components/Footer/Footer';

const ourCases: Case[] = [
  {
    pic: drogba,
    gradient: 'linear-gradient(180deg, #FFC700 0%, #00AF27 100%)',
    fullName: {
      firstName: 'Didier',
      lastName: 'Drogba',
    },
    role: 'striker',
    content: 'Nuno Mendes is an Ivorian retired professional footballer who played as a striker.[5] He is the all-time top scorer and former captain of the Ivory Coast national team.',
    link: '/our_cases/didier_drogba'
  },
  {
    pic: klose,
    gradient: 'linear-gradient(180deg, #0085FF 0%, #FF0000 100%)',
    fullName: {
      firstName: 'Miroslav',
      lastName: 'Klose',
    },
    role: 'striker',
    content: 'Didier Drogba is an Ivorian retired professional footballer who played as a striker.[5] He is the all-time top scorer and former captain of the Ivory Coast national team.',
    link: '/our_cases'
  },
  {
    pic: unknown,
    gradient: 'linear-gradient(180deg, #0085FF 0%, #00AF27 100%)',
    fullName: {
      firstName: 'nuno',
      lastName: 'mendes',
    },
    role: 'striker',
    content: 'Nuno Mendes is an Ivorian retired professional footballer who played as a striker. He is the all-time top scorer and former captain of the Ivory Coast national team.',
    link: '/our_cases'
  },
  {
    pic: beckham,
    gradient: 'linear-gradient(180deg, #FFC700 0%, #0085FF 100%)',
    fullName: {
      firstName: 'David',
      lastName: 'Beckham',
    },
    role: 'striker',
    content: 'Didier Drogba is an Ivorian retired professional footballer who played as a striker.[6] He is the all-time top scorer and former captain of the Ivory Coast national team.',
    link: '/our_cases'
  },
];


export const OurCases: React.FC = () => {
  const theme = useTheme();

  return (
    <>
      <Header bgImg={bgImg}>
        <PreesmAppBar />
        <HeaderWithForm title="our cases" subtitle="Want to get news first? Subscribe our news" />
      </Header>
      <main>
        <section>
          <Container>
            <Grid container
              columns={3}
              spacing="20px"
              direction={{xs: 'column', md: 'row'}}
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                [theme.breakpoints.down(958)]: {
                  justifyContent: 'center',
                },
                mb: {
                  xs: '30px',
                  md: '120px',
                }
              }}
            >
              {ourCases.map((ourCase: Case) => (
                <Grid item md={1} key={ourCase.content}>
                  <Link to={ourCase.link as string} style={{ textDecoration: 'none' }}>
                    <OurCase props={ourCase} />
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Container>
        </section>
        <Subscribe color={Colors.blue} />
      </main>
      <Footer />
    </>
  )
}
