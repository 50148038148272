import React from 'react';
import { Typography } from '@mui/material';

interface Props {
  text: string;
}

const SectionTitle = (props: Props) => {
  const { text } = props;

  return (
    <Typography
      variant="h2"
      align="center"
      sx={{
        mt: {xs: 9, md: 15},
        mb: {xs: 4, md: 5}
      }}
    >
    {text}
  </Typography>
  )
}

export default SectionTitle;
