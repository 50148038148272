import React from 'react';
import { Container, Grid, Typography, Link, Button} from '@mui/material';
import Logo from '../UI/Logo';
import FooterNavLink from '../UI/FooterNavLink';
import { Box } from '@mui/system';
import instagramIcon from '../../assets/footer/instagram.svg';
import { Link as RouterLink} from 'react-router-dom';

interface Props {
  
}

const Footer = (props: Props) => {
  const footerLink = {
    textDecoration: 'none',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontStyle: 'normal',
    fontWeight: 200,
    fontSize: '0.625rem',
    lineHeight: '1.125rem',
    color: 'text.primary'
  }

  return (
    <Box sx={{bgcolor: 'rgba(0,0,0,0.5)'}} component="footer">
      <Container sx={{position: 'relative'}}>
        <Box
          sx={{
            position: 'absolute',
            left: '50%',
            transform: {xs: 'translate(-50%)', md: 'translate(-85%)'},
            bottom: {xs: '20px', md: '16px'},
            margin: '0 auto',
            width: 'fit-content',
          }}
        >
          <Link
            href="#"
            sx={{...footerLink, mr: {xs: 1, md: 8}}}
          >
            Terms &amp; conditions apply &copy; Copyright 2021
          </Link>
          <Link
            href="#"
            sx={{...footerLink}}
          >
            Privacy policy
          </Link>
        </Box>
        <Grid container sx={{pt: 6, pb: {xs: 9, md: 2}, justifyContent: {sm: 'center'}}}>
          <Grid item xs={12} md={3} lg={3} sx={{mt: -2, mb: 4}}>
            <Logo />
          </Grid>
          <Grid item xs={8} sm={7} md={2} lg={2} container direction="column" sx={{
            alignItems: {sm: 'center', md: 'flex-start'},
            mx: {xs: 'auto', sm: 'auto', md: 'left'}
          }}>
            <FooterNavLink content="About us" path={'/about_us'} />
            <FooterNavLink content="Our news" path={'/our_news'} />
            <FooterNavLink content="Our cases" path={'our_cases'} />
          </Grid>
          <Grid item xs={8} sm={7} md={3} lg={3} container direction="column" sx={{
            alignItems: {sm: 'center', md: 'flex-start'},
            mx: {xs: 'auto', sm: 'auto', md: 'left'}
          }}>
            <FooterNavLink content="Our offer for players" path={'/players'} />
            <FooterNavLink content="Our offer for intermediaries" path={'/intermediaries'} />
          </Grid>
          <Grid item sm={12} md={4} lg={4} container justifyContent="center">
            <Grid item xs={8} sm={5} md={10} lg={6} container direction="column">
              <RouterLink to="/contact_us" style={{textDecoration: 'none'}}>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{px: '36px'}}
                >
                  contact us
                </Button>
              </RouterLink>
              <Typography
                variant="h4"
                color="text.primary"
                sx={{
                  mt: 8,
                  mb: 2,
                  fontSize: '0.875rem',
                  lineHeight: '0.875rem',
                }}
              >
                follow us
              </Typography>
              <Grid container justifyContent="space-between">
                <Link
                  href="https://instagram.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={instagramIcon} alt="instagram" />
                </Link>
                <Link
                  href="https://instagram.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={instagramIcon} alt="instagram" />
                </Link>
                <Link
                  href="https://instagram.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={instagramIcon} alt="instagram" />
                </Link>
                <Link
                  href="https://instagram.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={instagramIcon} alt="instagram" />
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default Footer;
