import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTheme } from '@mui/system';
import HeaderMenuItemPopper from './HeaderMenuItemPopper';
import caret_down from '../../assets/header/caret_down.svg';
import styles from './HeaderMenu.module.scss';

const HeaderMenu: React.FC = () => {
  const [isHeaderMenuItemActive, setIsHeaderMenuItemActive] = useState(false);

  const handleMenu = () => {
    setIsHeaderMenuItemActive(!isHeaderMenuItemActive);
  }

  const theme = useTheme();
 
  return (
    <ul className={styles.headerMenu}>
      <li
        className={`${styles.headerMenu__item}`}
        onMouseEnter={handleMenu}
        onMouseLeave={handleMenu}
        onClick={handleMenu}
      >
        <span className={styles.headerMenu__link}>our offer</span>
        <img src={caret_down} alt="caret down" className={styles.headerMenu__caretDown}/>
        {isHeaderMenuItemActive && (
          <HeaderMenuItemPopper
            onMouseLeave={handleMenu}
            isHeaderMenuItemActive={isHeaderMenuItemActive}
          />
        )}
      </li>
      <li className={styles.headerMenu__item}>
        <NavLink
          to='/about_us'
          className={styles.headerMenu__link}
          activeStyle={{
            borderBottom: `1px solid ${theme.palette.text.primary}`,
          }}
        >
          about us
        </NavLink>
      </li>
      <li className={styles.headerMenu__item}>
        <NavLink
          to='/our_news'
          className={styles.headerMenu__link}
          activeStyle={{
            borderBottom: `1px solid ${theme.palette.text.primary}`,
          }}
        >
          our news
        </NavLink>
      </li>
    </ul>
  );
}

export default HeaderMenu

