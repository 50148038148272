import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Navigation, Pagination } from 'swiper';
import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module

import drogba from '../../assets/ourCases/drogba.jpg';
import mendes from '../../assets/ourCases/mendes.jpg';
import OurCase from './OurCase';
import Case from '../../types/case';
import { Box, Button, Container, Typography } from '@mui/material';

import './OurCasesSwipe.scss';
import { useTheme } from '@mui/system';
import SectionTitle from '../UI/SectionTitle';
import { Link } from 'react-router-dom';

interface Props {
  
}

const ourCases: Case[] = [
  {
    pic: mendes,
    gradient: 'linear-gradient(180deg, #00AF27 0%, #FF0000 100%);',
    fullName: {
      firstName: 'Nuno',
      lastName: 'Mendes',
    },
    role: 'defensive Midfielder',
    content: 'Nuno Mendes is an Ivorian retired professional footballer who played as a striker.[5] He is the all-time top scorer and former captain of the Ivory Coast national team.',
  },
  {
    pic: drogba,
    gradient: 'linear-gradient(180deg, #FFC700 0%, #00AF27 100%)',
    fullName: {
      firstName: 'didier',
      lastName: 'drogba',
    },
    role: 'striker',
    content: 'Didier Drogba is an Ivorian retired professional footballer who played as a striker.[5] He is the all-time top scorer and former captain of the Ivory Coast national team.',
  },
  {
    pic: mendes,
    gradient: 'linear-gradient(180deg, #00AF27 0%, #0085FF 100%);',
    fullName: {
      firstName: 'nuno',
      lastName: 'mendes',
    },
    role: 'striker',
    content: 'Nuno Mendes is an Ivorian retired professional footballer who played as a striker. He is the all-time top scorer and former captain of the Ivory Coast national team.',
  },
];



const OurCasesSwipe = (props: Props) => {
  const theme = useTheme();

  const styles = {
    gradientBox: {
      position: 'absolute',
      top: '0%',
      left: '-0.4%',
      bottom: '0%',
      bgcolor: 'red',
      width: '20%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      zIndex: 1,
      background: 'linear-gradient(270deg, #0F1521 72.6%, rgba(15, 21, 33, 0) 100%)',
      transform: 'rotate(180deg)',
      '@media (max-width: 874px)': {
        width: '15%'
      },
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    }
  } as const;

  return (
    <section>
      <Container>
        <SectionTitle text="our cases" />
        <Box sx={{
          position: 'relative',
        }}>
          <Swiper
            modules={[Navigation, Pagination]}
            spaceBetween={140}
            centeredSlides={true}
            slidesPerView="auto"
            navigation={{
              nextEl: '.next',
              prevEl: '.prev',
            }}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            loop={true}
            loopAdditionalSlides={0}
            breakpoints={{
              // when window width is >= 320px
              320: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: "auto",
                spaceBetween: 70
              },
              1114: {
                slidesPerView: "auto",
                spaceBetween: 140
              }
            }}
          >
            {ourCases.map((ourCase: Case) => (
              <SwiperSlide key={ourCase.content}>
                <Box sx={{
                  maxWidth: '388px',
                  margin: {xs: '0 auto'},
                  [theme.breakpoints.down('sm')]: {
                    minWidth: '0px',
                    px: '40px'
                  },
                }}>
                  <OurCase props={ourCase} />
                  <Typography
                    sx={{
                      maxWidth: '388px',
                      maxHeight: '114px',
                      overflow: 'hidden',
                      mb: {xs: '30px', md: '40px'},
                      mt: {xs: '20px', md: '40px'},
                    }}
                    >
                    {ourCase.content}
                  </Typography>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
          <Box sx={{
            ...styles.gradientBox,
          }}></Box>
          <Box sx={{
            ...styles.gradientBox,
            left: '100.4%',
            transform: 'translate(-100%)',
            '@media (max-width: 874px)': {
              transform: 'translate(-80%)'
            },
          }}></Box>
          <div className="prev" />
          <div className="next" />
        </Box>
        <Link to="/our_cases" style={{textDecoration: 'none'}}>
          <Box sx={{m: '0 auto', mb: {xs: '70px', md: '120px'}, width: 'fit-content'}}>
            <Button
              variant="contained"
              fullWidth
              sx={{px: '36px'}}
            >
              details
            </Button>
          </Box>
        </Link>
      </Container>
    </section>
  )
}

export default OurCasesSwipe;
