import React, { ChangeEvent, useState } from 'react'
import { Alert, Button, Grid, Snackbar, TextField, useTheme } from '@mui/material'
import CustomSelect from './CustomSelect/CustomSelect'
import { emailRegEx } from '../../utils/emailRegEx';
import { SubscriptionResponse } from '../../types/subscriptionResponse';
import { sendSubscribtionData } from '../../utils/api';
import { textInputStyle } from '../../styles/textInputStyle';

interface Props {
  justifyContent?: string;
}

export const SubscribeForm = (props: Props) => {
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<boolean>(false);
  const [role, setRole] = useState<string>('Player');
  const [open, setOpen] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const theme = useTheme();

  const emailHandler = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setEmail(event.target.value); setEmailError(false)
  }

  const subscribeFormHandler = async (email: string, role: string) => {
    if (!emailRegEx.test(email)) {
      setEmailError(true);
    } else {
      setEmailError(false);

      let response: SubscriptionResponse = await sendSubscribtionData(email, role);
      
      if (typeof response === 'string') {
        setErrorMessage(response);
        setOpen(true);

        return;
      }

      if (response.error) {
        setErrorMessage(response.error);
      } else {
        setErrorMessage("You've been subscribed!");
        setIsSubscribed(true);
      }

      setOpen(true);
    }
  }

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const { justifyContent = 'flex-start' } = props;

  return (
    <Grid container columns={9} columnSpacing="32px" rowSpacing="20px" justifyContent={justifyContent}>
      <Grid item xs={9} sm={3} lg={4}>
        <TextField
          type="email"
          required={true}
          error={emailError}
          helperText={emailError ? 'Invalid Email' : ''}
          variant="outlined"
          fullWidth
          hiddenLabel={true}
          placeholder="* Email"
          value={email}
          onChange={emailHandler}
          sx={{
            '.MuiOutlinedInput-input': {
              ...textInputStyle['.MuiOutlinedInput-input'],
              lineHeight: '1.125rem',
              height: 'fit-content',
            },
            '.MuiFormHelperText-root': {
              ...theme.typography.body2,
              textTransform: 'none',
              position: 'absolute',
              top: '104%',
              width: '100%',
              height: 'fit-content',
            }
          }}
        />
      </Grid>
      <Grid item xs={9} sm={3} lg={2}>
        <CustomSelect setRole={setRole} />
      </Grid>
      <Grid item xs={9} sm={3} lg={2} sx={{mt: {xs: '30px', sm: '0px'}}}>
        <Button
          variant="contained"
          fullWidth={true}
          sx={{px: '36px'}}
          onClick={() => subscribeFormHandler(email, role)}
        >
          subscribe
        </Button>
      </Grid>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={isSubscribed ? 'success' : 'error'} sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Grid>
  )
}
