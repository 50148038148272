import React from 'react';
import Container from '@mui/material/Container';
import SectionTitle from '../UI/SectionTitle';
import { Grid, Typography } from '@mui/material';

import threeYoungPlayers from '../../assets/ourValues/three_young_players.jpg';
import fourYoungPlayers from '../../assets/ourValues/four_young_players.jpg';


interface Props {
  
}

const OurValues = (props: Props) => {
  const paragraphStyle = {
    mb: '20px',
  };

  const picStyle = {
    marginBottom: `${paragraphStyle.mb}`,
  }

  return (
    <section>
      <Container sx={{mb: {xs: '70px', md: '120px'}}}>
        <SectionTitle text="our values" />
        <Grid container justifyContent="center">
          <Grid item xs={12} md={10}>
            <Grid container columnSpacing="36px">
              <Grid item xs={12} sm={6} container direction="column">
                <img src={threeYoungPlayers} alt="three kids playing football" width="100%" style={picStyle} />
                <Typography variant="body1" sx={{...paragraphStyle}}>
                  Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam
                </Typography>
                <Typography variant="body1" sx={{...paragraphStyle}}>
                  Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} container direction="column">
                <Typography variant="body1" sx={{...paragraphStyle}}>
                Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam
                </Typography>
                <img src={fourYoungPlayers} alt="four kids playing football" width="100%" style={picStyle} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

export default OurValues;
