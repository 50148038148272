import React, { useState } from 'react';
import {ReactComponent as MenuIcon } from '../../assets/header/menu_icon.svg';
import ExpandLess from '../../assets/header/caret_right_white.svg';
import {ReactComponent as ExpandMore } from '../../assets/header/caret_down.svg';
import { Collapse, IconButton, List, ListItemButton, SwipeableDrawer, Typography, useTheme } from '@mui/material';
import { NavLink } from 'react-router-dom';

interface Props {
  
}

const HeaderMenuMobile = (props: Props) => {
  const iOS =
  typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const theme = useTheme();

  const navFont = {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontWeight: 900,
    fontStyle: 'italic',
    fontSize: '0.875rem', // 14px (base: 16px)
    lineHeight: "1rem",
    letterSpacing: '0.05em',
    textDecoration: 'none',
    textTransform: 'uppercase',
  } as const;

  return (
    <>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        anchor={'left'}
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        onOpen={() => setIsDrawerOpen(true)}
        sx={{
            '.MuiDrawer-paperAnchorLeft': {
              backgroundImage: 'linear-gradient(180deg, #0F1521 7.59%, rgba(0, 0, 0, 0) 16.96%, #0F1521 100%)',
            }
          }
        }
      >
        <List
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'transparent' }}
          component="nav"
        >
          <ListItemButton onClick={handleClick}>
            <Typography sx={{...navFont, pr: 2}}>our offer</Typography>
            {open ? <img src={ExpandLess} height="12px" alt="expand less" /> : <ExpandMore />}
            
          </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{'&.MuiListItemButton-root': {p: 0}}}>
                  <Typography
                    sx={{
                      ...navFont,
                      color: 'text.primary',
                      display: 'block',
                      width: '100%',
                      height: '100%',
                      px: 4,
                      py: 1,
                    }}
                    component={NavLink}
                    to="/players"
                    activeStyle={{
                      color: `${theme.palette.secondary.main}`,
                    }}
                  >
                    players
                  </Typography>
                </ListItemButton>
                <ListItemButton sx={{'&.MuiListItemButton-root': {p: 0}}}>
                  <Typography
                    sx={{
                      ...navFont,
                      color: 'text.primary',
                      display: 'block',
                      width: '100%',
                      height: '100%',
                      px: 4,
                      py: 1,
                    }}
                    component={NavLink}
                    to="/intermediaries"
                    activeStyle={{
                      color: `${theme.palette.secondary.main}`,
                    }}
                  >
                    intermediaries
                  </Typography>
                </ListItemButton>
              </List>
            </Collapse>
            {/* <ListItemButton selected={false} sx={{
              '&.MuiListItemButton-root.Mui-selected': { bgcolor: '#fff' },
                '& > .MuiTypography-root': {color: 'secondary.main' },
            }}>
              <Typography sx={navFont}>about us</Typography>
            </ListItemButton> */}
          <ListItemButton sx={{'&.MuiListItemButton-root': {p: 0}}}>
            <Typography
              sx={{
                ...navFont,
                color: 'text.primary',
                display: 'block',
                width: '100%',
                height: '100%',
                px: 2,
                py: 1,
              }}
              component={NavLink}
              to="/about_us"
              activeStyle={{
                color: `${theme.palette.secondary.main}`,
              }}
            >
              about us
            </Typography>
          </ListItemButton>
          <ListItemButton sx={{'&.MuiListItemButton-root': {p: 0}}}>
            <Typography
              sx={{
                ...navFont,
                color: 'text.primary',
                display: 'block',
                width: '100%',
                height: '100%',
                px: 2,
                py: 1,
              }}
              component={NavLink}
              to="/our_news"
              activeStyle={{
                color: `${theme.palette.secondary.main}`,
              }}
            >
              our news
            </Typography>
          </ListItemButton>
        </List>
      </SwipeableDrawer>
      <IconButton
        size="large"
        color="inherit"
        aria-label="menu"
        sx={{ ml: 'auto', display: { md: 'none'} }}
        onClick={() => setIsDrawerOpen((currentState: boolean) => !currentState)}
      >
        <MenuIcon />
      </IconButton>
    </>
  )
}

export default HeaderMenuMobile

