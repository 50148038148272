import React from 'react';
import { Container, Grid, Typography, useTheme } from '@mui/material';

interface Props {
  content: string;
}

const HeaderTitle = (props: Props) => {
  const theme = useTheme();

  const { content } = props;

  return (
    <Container sx={{height: '100%', pt: '80px'}}>
      <Grid container alignItems="center" justifyContent="center" sx={{height: '100%'}}>
        <Typography
          variant="h1"
          sx={{
            lineHeight: '3.688rem',
            letterSpacing: '0.21em',
            wordWrap: 'break-word',
            hyphens: 'manual',
            zIndex: 99,
            [theme.breakpoints.down('sm')]: {
              fontSize: '2rem',
              lineHeight: '2.438rem',
            },
          }}
        >
          {content}
        </Typography>
      </Grid>
    </Container>
  );
}

export default HeaderTitle;
